
import { Vue, Component, Watch } from 'vue-property-decorator'
import DispatchMonthCalendar from '@/components/DispatchMonthCalendar.vue'
import DispatchWeekCalendar from '@/components/DispatchWeekCalendar.vue'
import DispatchDayCalendar from '@/components/DispatchDayCalendar.vue'
import DispatchListCalendar from '@/components/DispatchListCalendar.vue'
import DispatchLeftSidebar from '@/components/DispatchLeftSidebar.vue'
import DispatchHeader from '@/components/DispatchHeader.vue'
import dispatch from '@/store/modules/dispatch'
import client from '@/services/dispatch'
import { DateInterval, DispatchBlock } from '@/models/dto/Dispatch'
import dayjs from 'dayjs'
import { buildModel } from '@/utils/assignments'
import { Interval } from '@flatten-js/interval-tree'
import sidebar from '@/store/modules/sidebar'
import ReservationDetailPanel from '@/components/ReservationDetailPanel.vue'
import { EventBus } from '@/utils/eventBus'
import reservation from '@/store/modules/reservation'
import auth from '@/store/modules/auth'
import { getFormattedReservations } from '@/utils/dispatch'

@Component({
  components: {
    DispatchMonthCalendar,
    DispatchWeekCalendar,
    DispatchDayCalendar,
    DispatchListCalendar,
    DispatchLeftSidebar,
    DispatchHeader,
  },
  metaInfo: {
    title: 'Dispatch',
  },
})
export default class Dispatch extends Vue {
  dispatch = dispatch
  reservations: Record<string, DispatchBlock> = {}

  @Watch('dispatch.getCurrentDate')
  @Watch('dispatch.getMode')
  updateReservations(): void {
    this.getReservations(this.dispatchRequest)
  }

  // Temporary numbers
  get dispatchRequest(): DateInterval {
    return {
      startDatetime: dispatch.getCurrentDate
        .add(-1, 'month')
        .format('YYYY-MM-DD'),
      endDatetime: dispatch.getCurrentDate.add(1, 'month').format('YYYY-MM-DD'),
    }
  }

  isNewDispatchRequestNecessary(dispatchRequest: DateInterval): boolean {
    const lowOfDispatchRequest = dayjs(dispatchRequest.startDatetime).valueOf()
    const highOfDispatchRequest = dayjs(dispatchRequest.endDatetime).valueOf()
    const searchInterval = new Interval(
      lowOfDispatchRequest,
      highOfDispatchRequest
    )

    // This only works thanks to the logic in the dispatch state module that ensures there
    // are no overlaps in the intervals of dispatch.getLoadedDispatchRequestDateIntervals,
    // otherwise this wouldn't be a conclusive check
    const intervals =
      dispatch.getLoadedDispatchRequestDateIntervals.search(searchInterval)

    for (const interval of intervals) {
      const lowOfInterval = interval[0]
      const highOfInterval = interval[1]
      if (
        lowOfInterval <= lowOfDispatchRequest &&
        highOfInterval >= highOfDispatchRequest
      ) {
        return false
      }
    }
    return true
  }

  async getReservations(
    dispatchRequest: DateInterval,
    options = {
      ignoreCache: false,
    }
  ): Promise<void> {
    if (
      this.isNewDispatchRequestNecessary(dispatchRequest) ||
      options.ignoreCache
    ) {
      const reservations = await getFormattedReservations(dispatchRequest)

      dispatch.updateLoadedDispatchRequestDateIntervals(dispatchRequest)
      dispatch.updateLoadedReservations(reservations)
      dispatch.setLoading(false)
    }

    this.reservations = dispatch.getLoadedReservations
  }

  get reservationsInCorrectTimeZone(): DispatchBlock[] {
    if (dispatch.isLoading) {
      return []
    }
    return Object.values(this.reservations).map((reservation) => {
      return {
        ...reservation,
        startDate: dayjs(reservation.startDate)
          .tz(auth.getUserTimeZone)
          .format('YYYY-MM-DD hh:mm a'),
        endDate: dayjs(reservation.endDate)
          .tz(auth.getUserTimeZone)
          .format('YYYY-MM-DD hh:mm a'),
      }
    })
  }

  openReservationDetailPanel(resIds: {
    reservationId: number
    managedId: string
  }): void {
    sidebar.popAllAndPush({
      component: ReservationDetailPanel,
      props: resIds,
      width: 764,
      wide: true,
      persist: true,
      onClose: () => reservation.clear(),
    })
  }

  get reservationsToDisplay(): DispatchBlock[] {
    let reservations = [...this.reservationsInCorrectTimeZone]

    // Status Filter
    if (dispatch.getSelectFilters['statusKeys']?.length) {
      reservations = reservations.filter((reservation) => {
        return dispatch.getSelectFilters['statusKeys'].includes(
          reservation.reservationStatusKey
        )
      })
    }

    // Assignment Status Filter
    if (dispatch.getSelectFilters['assignmentStatusKeys']?.length) {
      reservations = reservations.filter((reservation) => {
        if (
          reservation.isFullyAssigned &&
          dispatch.getSelectFilters['assignmentStatusKeys'].find((key) => {
            return key === 'fully assigned'
          })
        ) {
          return true
        }
        if (
          !reservation.isFullyAssigned &&
          dispatch.getSelectFilters['assignmentStatusKeys'].find((key) => {
            return key === 'needs assignment'
          })
        ) {
          return true
        }
        return false
      })
    }

    // Driver Filter
    if (dispatch.getSelectFilters['driverIds']?.length) {
      reservations = reservations.filter((reservation) => {
        const driverIds = reservation.vehicleAssignments
          ?.flatMap((va) => va.driverAssignments)
          .map((da) => da.userId)

        return dispatch.getSelectFilters['driverIds'].some((driverId) =>
          driverIds.includes(driverId)
        )
      })
    }

    // Vehicle Name Filter
    if (dispatch.getSelectFilters['vehicleIds']?.length) {
      reservations = reservations.filter((reservation) => {
        const vehicleIds = reservation.vehicleAssignments?.map(
          (va) => va.vehicleId
        )
        return dispatch.getSelectFilters['vehicleIds'].find((vehicleId) => {
          return vehicleIds.find((id) => id === vehicleId)
        })
      })
    }

    // Vehicle Type Filter
    if (dispatch.getSelectFilters['vehicleTypeIds']?.length) {
      reservations = reservations.filter((reservation) => {
        const vehicleTypeIds = reservation.vehicleAssignments?.map(
          (va) => va.vehicleTypeId
        )
        return dispatch.getSelectFilters['vehicleTypeIds'].find(
          (vehicleTypeId) => {
            return vehicleTypeIds.find((id) => id === vehicleTypeId)
          }
        )
      })
    }

    // Garage Filter
    if (dispatch.getSelectFilters['garageIds']?.length) {
      reservations = reservations.filter((reservation) => {
        return dispatch.getSelectFilters['garageIds'].find((id) => {
          return (
            id === reservation.departureGarageId ||
            id === reservation.returnGarageId
          )
        })
      })
    }

    reservations = this.searchbarFilter(reservations)

    return reservations
  }

  searchbarFilter(reservations: DispatchBlock[]): DispatchBlock[] {
    if (!dispatch.getSearchQuery.length) {
      return reservations
    }

    const query = dispatch.getSearchQuery.toLowerCase()

    return reservations.filter((reservation) => {
      return (
        reservation.reservationId?.toString().toLowerCase()?.includes(query) ||
        reservation.managedId?.toString().toLowerCase()?.includes(query) ||
        reservation.customerName?.toLowerCase()?.includes(query) ||
        reservation.customerCompany?.toLowerCase()?.includes(query) ||
        reservation.customerAccountGroup?.toLowerCase()?.includes(query)
      )
    })
  }

  async refreshDispatch(dispatchRequest: DateInterval): Promise<void> {
    const request = dispatchRequest || this.dispatchRequest
    await this.getReservations(request, { ignoreCache: true })
  }

  async mounted(): Promise<void> {
    EventBus.$on(
      'dispatch:open-reservation-detail-panel',
      this.openReservationDetailPanel
    )
    EventBus.$on('dispatch:refresh', (dispatchRequest: DateInterval) =>
      this.refreshDispatch(dispatchRequest)
    )

    await this.getReservations(this.dispatchRequest)
    dispatch.setCurrentDate(dayjs())
  }

  beforeDestroy(): void {
    EventBus.$off(
      'dispatch:open-reservation-detail-panel',
      this.openReservationDetailPanel
    )
    EventBus.$off('dispatch:refresh', (dispatchRequest: DateInterval) =>
      this.refreshDispatch(dispatchRequest)
    )
  }

  destroyed(): void {
    reservation.clear()
  }
}
