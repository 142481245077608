var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('CalendarView',{ref:"calendar",staticClass:"border-0 border-radius-12 box-shadow-medium vue-simple-calendar--month",style:(_vm.cssVars),attrs:{"show-date":_vm.dispatch.getCurrentDate.toDate(),"items":_vm.reservationsInMonth,"weekday-name-format":"long","item-top":`${_vm.ITEM_TOP}px`,"item-content-height":`${_vm.ITEM_CONTENT_HEIGHT}px`,"item-border-height":`${_vm.ITEM_BORDER_HEIGHT}px`},scopedSlots:_vm._u([{key:"dayHeader",fn:function({ label }){return [_c('div',{staticClass:"border-0 d-flex background-secondary-light w-full align-center justify-space-around font-14 text-secondary font-semibold",style:({ height: '30px' })},[_vm._v(" "+_vm._s(label)+" ")])]}},{key:"dayContent",fn:function({ day }){return [_c('v-container',{staticClass:"padding-a-0 margin-a-0",class:{
          'background-white': _vm.dayjs(day).isSameOrAfter(_vm.dayjs(), 'day'),
        }},[_c('v-row',{staticClass:"justify-center padding-a-1 margin-a-0"},[_c('div',{staticClass:"padding-a-2 font-14 h-32 w-32 d-flex justify-center align-center",class:{
              'background-green-10 border-radius-round text-secondary': _vm.dayjs(
                day
              ).isSame(_vm.dayjs(), 'day'),
              'text-text-gray-1':
                _vm.dayjs(day).format('MM') !==
                _vm.dispatch.getCurrentDate.format('MM'),
            }},[_vm._v(" "+_vm._s(_vm.dayjs(day).format('D'))+" ")])]),(_vm.showMoreButton(day))?_c('div',{staticClass:"position-absolute bottom-4 left-0"},[_c('CUButton',{staticClass:"border-0 border-orange border-radius-8 font-12 font-weight-regular text-text-gray-1 background-transparent hover:background-bg-gray-1 padding-y-2 padding-x-1",attrs:{"height":"20","width":"auto","color":"gray-bg"},on:{"click":function($event){return _vm.toggleShowMore(day)}}},[_vm._v(" "+_vm._s(_vm.moreButtonCount(day))+" ")])],1):_vm._e(),(_vm.dispatch.isLoading)?_c('div',{staticClass:"h-100 w-full padding-x-2"},[(Math.random() < 0.5)?_c('CUSkeletonLoader',{staticClass:"margin-b-2",attrs:{"height":"20px","width":"100%"}}):_vm._e(),(Math.random() < 0.5)?_c('CUSkeletonLoader',{staticClass:"margin-b-2",attrs:{"height":"20px","width":"100%"}}):_vm._e(),(Math.random() < 0.5)?_c('CUSkeletonLoader',{staticClass:"margin-b-2",attrs:{"height":"20px","width":"100%"}}):_vm._e()],1):_vm._e()],1)]}},{key:"item",fn:function({ value, weekStartDate }){return [(_vm.showReservation(value, weekStartDate))?_c('DispatchMonthCalendarItem',{attrs:{"reservation":value.originalItem,"classes":value.classes,"top":_vm.getTopForReservation(weekStartDate, value.originalItem)}}):_c('div')]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }