var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"hover:cursor-pointer",class:{
    'w-full': _vm.fullWidth,
    'cv-item position-absolute': _vm.cvItem,
    'position-relative': !_vm.cvItem,
  },on:{"click":_vm.openResDetailPanel}},[_c('div',{staticClass:"border-radius-5 padding-y-1",class:{
      'margin-r-1 margin-y-1 position-relative': _vm.cvItem,
      'margin-y-1': !_vm.cvItem,
      [_vm.blockBackgroundColor]: true,
    },style:(_vm.innerWrapperStyle)},[_c('div',{staticClass:"h-full position-absolute top-8 bottom-8",class:_vm.blockPrimaryColor,style:({
        width: '3px',
        height: 'calc(100% - 16px)',
        borderRadius: '50px',
        left: '2px',
      })}),_c('div',{style:({ marginLeft: '7px' })},[_c('div',{style:({ marginBottom: '2px' })},[_c('p',{staticClass:"margin-a-0 font-12 leading-16 font-bold",class:{
            'overflow-hidden display-webkit-box': !_vm.cvItem,
            'white-space-normal': _vm.cvItem,
          },style:(_vm.itemHeaderStyle)},[_vm._v(" "+_vm._s(_vm.getHeaderLabelForReservation(_vm.reservation))+" ")])]),_c('div',{class:{ 'd-flex max-h-36': _vm.isMultiDay }},[_vm._l((_vm.reservation.model),function(assignment,assignmentIndex){return [(assignmentIndex + 1 <= _vm.maxAssignments)?_c('div',{key:`assignment-${_vm.reservation.reservationId}-${assignmentIndex}`,staticClass:"d-flex margin-b-1",class:{
              'margin-r-2': _vm.isMultiDay,
            }},[_c('CUIcon',{style:({
                marginRight: '2px',
                marginTop: '1px',
                minWidth: '14px',
              }),attrs:{"aria-label":"Vehicle","height":"14px","width":"14px","color":_vm.iconColor}},[_vm._v(" bus_plain ")]),_c('div',{staticClass:"overflow-hidden position-relative",class:{
                'max-w-100': _vm.cvItem,
              }},[_c('p',{staticClass:"font-12 leading-16 min-h-16 text-overflow-ellipsis overflow-hidden"},[_vm._v(" "+_vm._s(assignment.vehicleName || ' - ')+" ")]),_vm._l((assignment.driverAssignments),function(driverAssignment,di){return _c('p',{key:`driver-assignment-${_vm.reservation.reservationId}-${assignmentIndex}-${di}`,staticClass:"font-12 leading-16 min-h-16 text-overflow-ellipsis overflow-hidden"},[_vm._v(" "+_vm._s(driverAssignment.driverName)+" ")])}),(!assignment.driverAssignments.length)?_c('p',[_vm._v("-")]):_vm._e()],2)],1):_vm._e()]})],2)])]),(_vm.hasFarmout)?_c('div',{staticClass:"position-absolute h-12 w-12 d-flex align-center justify-center rounded-pill background-additional-blue-2 border-1 border-solid border-additional-blue",class:{
      'bottom-9 right-11': _vm.cvItem,
      'bottom-6 right-6': !_vm.cvItem,
    }},[_c('p',{staticClass:"text-additional-blue font-bold font-9"},[_vm._v("F")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }