
import { Vue, Component, Prop } from 'vue-property-decorator'
import { indexToWeekday } from '@/utils/date'
import dispatch from '@/store/modules/dispatch'
import { Coordinates, DispatchBlock } from '@/models/dto/Dispatch'
import { doBoxesOverlap, doesBlockOverlapDates } from '@/utils/dispatch'
import dayjs, { Dayjs } from 'dayjs'
import DispatchCalendarItem from '@/components/DispatchCalendarItem.vue'
import deepClone from '@/utils/deepClone'
import DispatchMultidayCalendarItem from '@/components/DispatchMultidayCalendarItem.vue'
import auth from '@/store/modules/auth'
@Component({
  components: { DispatchCalendarItem, DispatchMultidayCalendarItem },
})
export default class DispatchDayCalendar extends Vue {
  @Prop({}) readonly reservations!: DispatchBlock[]

  value = ''
  indexToWeekday = indexToWeekday
  state = dispatch
  dayjs = dayjs

  get dayStart(): Dayjs {
    return dispatch.getCurrentDate.tz(auth.getUserTimeZone).startOf('day')
  }

  get dayEnd(): Dayjs {
    return dispatch.getCurrentDate.tz(auth.getUserTimeZone).endOf('day')
  }

  get multiDayItems(): DispatchBlock[] {
    return this.currentDayItems.filter((r) => r.isMultiDay)
  }

  get currentDayItems(): DispatchBlock[] {
    const reservationsInDay = deepClone(this.reservations).filter((r) =>
      doesBlockOverlapDates(r, this.dayStart, this.dayEnd, false)
    )

    // Iterate through the reservations and clip start/end dates
    for (const reservation of reservationsInDay) {
      if (reservation.startDatetime === reservation.endDatetime) {
        reservation.endDate = dayjs(reservation.startDatetime)
          .add(45, 'minute')
          .format('YYYY-MM-DD HH:mm:ss')
        reservation.durationInHours = 0.75
      }

      if (
        dayjs(reservation.startDate).isBefore(this.dayStart) &&
        dayjs(reservation.endDate).isAfter(this.dayEnd)
      ) {
        reservation.isMultiDay = true
        continue
      }

      // If the reservation starts before today, set its start at midnight
      if (dayjs(reservation.startDate).isBefore(this.dayStart)) {
        reservation.startDate = this.dayStart.format('YYYY-MM-DD kk:mm:ss')
      }

      // If the reservation ends after today, set its start at 11:59pm
      if (dayjs(reservation.endDate).isAfter(this.dayEnd)) {
        reservation.endDate = this.dayEnd.format('YYYY-MM-DD kk:mm:ss')
      }

      // Update duration with new times
      reservation.durationInHours = Math.abs(
        dayjs(reservation.endDate).diff(reservation.startDate, 'hours')
      )
    }

    return reservationsInDay
  }

  // Loop through all dispatch blocks, and return the rightmost
  // x1 value that's found
  get minWidthOfCalendar(): number {
    const ITEM_WIDTH = 150
    const windowWidth = window.innerWidth
    const outerPadding = 64
    const SIDEBAR_WIDTH = 274

    const rightMostCoordinate = this.plottedDayItems.reduce((coordinate, r) => {
      return Math.max(r.coordinates.x1, coordinate)
    }, 0)

    const rightMostPixel = rightMostCoordinate * ITEM_WIDTH
    return Math.max(windowWidth - outerPadding - SIDEBAR_WIDTH, rightMostPixel)
  }

  get plottedDayItems(): DispatchBlock[] {
    const plottedItems = this.currentDayItems.filter((r) => !r.isMultiDay)
    const storedCoordinates = []

    for (const reservation of plottedItems) {
      let initialCoordinates = this.getCoordinatesForReservation(reservation)

      for (let i = 0; i < storedCoordinates.length; i++) {
        if (doBoxesOverlap(storedCoordinates[i], initialCoordinates)) {
          initialCoordinates = this.getCoordinatesForReservation(
            reservation,
            initialCoordinates.x0 + 1
          )
          i = -1
        }
      }

      reservation.coordinates = initialCoordinates
      storedCoordinates.push(initialCoordinates)
    }
    return plottedItems
  }

  // Calculate the distance from the top that the timebar should be
  get currentTimeDistanceFromTop(): string {
    const now = dayjs()

    if (!dispatch.getCurrentDate.isSame(now, 'day')) {
      return ''
    }

    const hour = now.hour()
    const minute = now.minute()
    const multidayWrapperRef: any = this.$refs['multiday-wrapper']
    const HEADER_HEIGHT = 56
    const INTERVAL_HEIGHT = 50
    let MULTIDAY_WRAPPER_HEIGHT = 0

    if (multidayWrapperRef) {
      MULTIDAY_WRAPPER_HEIGHT = multidayWrapperRef.offsetHeight
    }

    const distanceFromTop =
      HEADER_HEIGHT +
      MULTIDAY_WRAPPER_HEIGHT +
      hour * INTERVAL_HEIGHT +
      (minute / 60) * INTERVAL_HEIGHT

    return `${distanceFromTop}px`
  }

  get cssVars(): Record<string, string> {
    return {
      '--min-day-container-width': `${this.minWidthOfCalendar}px`,
    }
  }

  // Get the coordinates of the Dispatch block by getting its start/end time
  // on the 0-23 hour scale
  getCoordinatesForReservation(r: DispatchBlock, offsetX = 1): Coordinates {
    const startHour = dayjs(r.startDate).hour()
    const startMinutes = dayjs(r.startDate).minute()
    const endHour = dayjs(r.endDate).hour()
    const endMinutes = dayjs(r.endDate).minute()

    const y0 = Math.round(startHour * 4 + 1 + startMinutes / 15)
    const y1 = Math.round(endHour * 4 + 1 + endMinutes / 15)

    const x0 = offsetX
    const x1 = x0 + 1

    return { x0, x1, y0, y1 }
  }
}
