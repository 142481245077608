
import { DispatchBlock } from '@/models/dto/Dispatch'
import { formatFullName } from '@/utils/string'
import dayjs from 'dayjs'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { EventBus } from '@/utils/eventBus'

@Component({})
export default class DispatchMonthCalendarItem extends Vue {
  @Prop({})
  reservation!: DispatchBlock

  @Prop({
    required: true,
    type: String,
  })
  top!: string

  @Prop({
    required: true,
    type: Array,
  })
  classes!: string[]

  dayjs = dayjs
  formatFullName = formatFullName

  get hasFarmout(): boolean {
    return this.reservation.affiliateOfferCount > 0
  }

  get title(): string {
    return `${this.reservation.managedId} ${this.simpleTitle}`
  }

  get simpleTitle(): string {
    return this.joinTitleParts([
      this.reservation.customerName,
      this.reservation.customerCompany,
      this.reservation.customerAccountGroup,
    ])
  }

  get simpleTitleWithoutName(): string {
    return this.joinTitleParts([
      this.reservation.customerCompany,
      this.reservation.customerAccountGroup,
    ])
  }

  private joinTitleParts(parts: string[]): string {
    return parts.filter((part) => !!part).join(', ')
  }

  get hoverText(): string {
    let hovertext = this.simpleTitleWithoutName

    if (hovertext) {
      hovertext += '<br>'
    }

    const assignments = this.extractAssignmentText(this.reservation)
    hovertext += assignments.join('<br>')

    return hovertext || this.simpleTitle
  }

  extractAssignmentText(reservation: DispatchBlock): string[] {
    const texts = []

    for (const assignment of reservation.model) {
      let text = `<strong>${
        assignment.vehicleName || assignment.vehicleTypeLabel
      }</strong>`

      const assignedDriverNames = (assignment.driverAssignments || [])
        .map((da) => da.driverName)
        .filter((name) => name) // This filters out any null or undefined driver names.

      const unassignedCount =
        assignment.driverAssignments.length - assignedDriverNames.length

      if (assignedDriverNames.length) {
        text += ` - ${assignedDriverNames.join(', ')}`
      }

      if (unassignedCount) {
        text += assignedDriverNames.length
          ? ` + ${unassignedCount} Unassigned`
          : ` - ${unassignedCount} Unassigned`
      }

      texts.push(text)
    }

    return texts
  }

  get isContinued(): boolean {
    return !!this.classes.find((classString) => {
      return classString === 'continued'
    })
  }

  get backgroundColor(): string {
    if (this.fullyAssigned) {
      return 'secondary-light'
    }
    return 'red-10-opaque'
  }

  get barColor(): string {
    if (this.fullyAssigned) {
      return 'secondary'
    }
    return 'rust'
  }

  get textColor(): string {
    if (this.fullyAssigned) {
      return 'black'
    }
    return 'rust'
  }

  get fullyAssigned(): boolean {
    return this.reservation.isFullyAssigned
  }

  openResDetailPanel(): void {
    EventBus.$emit('dispatch:open-reservation-detail-panel', {
      reservationId: this.reservation.reservationId,
      managedId: this.reservation.managedId,
    })
  }
}
