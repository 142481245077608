var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',{staticClass:"position-relative background-white border-radius-16 max-w-full margin-x-0 margin-y-0 padding-y-3"},[_vm._l((_vm.days),function(day,dayIndex){return _c('v-row',{key:`dispatch-list-view-day-${day.date}`,ref:`dispatch-list-view-day-${day.date}`,refInFor:true,staticClass:"margin-t-1 min-h-88",class:{
        'border-solid border-gray-border-light border-x-0 border-t-0 border-b-1 margin-b-2':
          dayIndex < 6,
        'margin-t-2': dayIndex > 0,
      },attrs:{"id":`dispatch-list-view-day-${day.date}`}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-row',[_c('v-col',{staticClass:"padding-x-0 padding-y-1 margin-b-1 margin-r-1 margin-t-n1",attrs:{"cols":"3"}},[_c('div',{staticClass:"w-32 h-32 font-16 text-gray-text-dark text-center text-no-wrap padding-t-1",class:{
                'background-green-10 border-radius-round':
                  _vm.dayjs(day.date).format('YYYY MM DD') ===
                  _vm.dayjs().format('YYYY MM DD'),
                'text-green': _vm.dayjs(day.date).isSameOrAfter(_vm.dayjs(), 'day'),
              }},[_vm._v(" "+_vm._s(_vm.dayjs(day.date).format('D'))+" ")])]),_c('v-col',{staticClass:"shrink padding-l-1 padding-y-1 margin-r-2 text-gray-text-dark text-no-wrap font-14",class:{
              'text-green': _vm.dayjs(day.date).isSameOrAfter(_vm.dayjs(), 'day'),
            }},[_vm._v(" "+_vm._s(_vm.dayjs(day.date).format('MMM | ddd'))+" ")])],1)],1),(!_vm.dispatch.isLoading)?_c('v-col',{staticClass:"grow padding-a-0"},_vm._l((day.reservations),function(reservation){return _c('DispatchListCalendarItem',{key:`dispatch-list-calendar-item-${reservation.reservationId}-${day.date}`,ref:`dispatch-list-calendar-item-${reservation.reservationId}-${day.date}`,refInFor:true,attrs:{"id":`dispatch-list-calendar-item-${reservation.reservationId}-${day.date}`,"reservation":reservation,"day":day}})}),1):_vm._e(),(_vm.dispatch.isLoading)?_c('v-col',{staticClass:"grow padding-a-0"},[_c('CUSkeletonLoader',{staticClass:"margin-y-1",attrs:{"height":"16px","width":`${Math.random() * 70 + 30}%`}}),(Math.random() < 0.5)?_c('CUSkeletonLoader',{staticClass:"margin-y-1",attrs:{"height":"16px","width":`${Math.random() * 70 + 30}%`}}):_vm._e(),(Math.random() < 0.5)?_c('CUSkeletonLoader',{staticClass:"margin-t-1",attrs:{"height":"16px","width":`${Math.random() * 70 + 30}%`}}):_vm._e()],1):_vm._e()],1)}),(_vm.distanceOfTimeMarkerFromTop && !_vm.dispatch.isLoading)?_c('div',{staticClass:"position-absolute h-1 left-0 border-0 background-primary w-full",style:({ top: `${_vm.distanceOfTimeMarkerFromTop}px` })}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }