
import { VehicleAssignment } from '@/models/dto'
import { DispatchBlock, ListDispatchBlock } from '@/models/dto/Dispatch'
import auth from '@/store/modules/auth'
import {
  getReservationTimeFormat,
  getReservationTimeLabel,
} from '@/utils/dispatch'
import { EventBus } from '@/utils/eventBus'
import dayjs from 'dayjs'
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'

@Component({})
export default class DispatchListCalendarItem extends mixins(DateMixin) {
  @Prop({ default: null }) readonly reservation!: ListDispatchBlock
  @Prop({ default: '' }) readonly day!: string

  dayjs = dayjs

  get isFullyAssigned(): boolean {
    return this.reservation.isFullyAssigned
  }

  get hasFarmout(): boolean {
    return this.reservation.affiliateOfferCount > 0
  }

  openResDetailPanel(): void {
    EventBus.$emit('dispatch:open-reservation-detail-panel', {
      reservationId: this.reservation.reservationId,
      managedId: this.reservation.managedId,
    })
  }

  reservationString(reservation: DispatchBlock): string {
    let simpleTitle = `${reservation.managedId}, ${reservation.customerName}`
    if (this.reservation.customerCompany) {
      simpleTitle = `${simpleTitle}, ${this.reservation.customerCompany}`
    }
    if (this.reservation.customerAccountGroup) {
      simpleTitle = `${simpleTitle}, ${this.reservation.customerAccountGroup}`
    }
    return simpleTitle
  }

  formatVehicleAssignment(vehicleAssignment: VehicleAssignment): string {
    return `${vehicleAssignment.vehicleName}`.concat(
      vehicleAssignment.driverAssignments
        .filter((driverAssignment) => !!driverAssignment.driverName)
        .reduce(
          (str, driverAssignment, currentIndex) =>
            `${str}${currentIndex === 0 ? '-' : ', '}${
              driverAssignment.driverName
            }`,
          ''
        )
    )
  }

  formatTimeslot(reservation: DispatchBlock): string {
    const startTimeZone = reservation.startTimeZone
    const endTimeZone = reservation.endTimeZone
    const userTimeZone = auth.getUserTimeZone
    let timeZoneLabel = ''

    if (startTimeZone !== userTimeZone) {
      const tzLabel = dayjs().tz(startTimeZone).format('z')
      timeZoneLabel = ` (${tzLabel})`
    } else if (endTimeZone !== userTimeZone) {
      const tzLabel = dayjs().tz(endTimeZone).format('z')
      timeZoneLabel = ` (${tzLabel})`
    }

    // Reservation starts and ends on this day
    if (this.reservation.type === 'single-day') {
      return `${getReservationTimeLabel(reservation)}`
    }
    // (Multiday) Reservation starts on a previous day and ends on this day
    if (this.reservation.type === 'multi-day-ending-today') {
      return `Multiday ending
      ${getReservationTimeFormat(
        dayjs(reservation.endDatetime).tz(
          reservation.endTimeZone || userTimeZone
        ),
        true
      )}${timeZoneLabel}`
    }
    // (Multiday) Reservation starts on a previous day and ends on a future day
    if (this.reservation.type === 'multi-day-spanning-today') {
      return `Multiday ending
      ${this.formatShortMonthDay(reservation.endDate, {
        tz: reservation.startTimeZone,
      })}${timeZoneLabel}`
    }
    // (Multiday) Reservation starts on this day and ends on a future day
    if (this.reservation.type === 'multi-day-starting-today') {
      return `${dayjs(reservation.startDate)
        .tz(reservation.startTimeZone)
        .format('ha')} ending
      ${this.formatShortMonthDay(reservation.endDate, {
        tz: reservation.startTimeZone,
      })}${timeZoneLabel}`
    }
  }
}
