import { VuexModule, Module, Action } from 'vuex-class-modules'
import store from '@/store/index'
import { DispatchViewOptions } from '@/utils/dispatch'
import dayjs from 'dayjs'
import { Reservation } from '@/models/dto'
import IntervalTree, { Interval } from '@flatten-js/interval-tree'
import { DateInterval, DispatchBlock } from '@/models/dto/Dispatch'
@Module({ generateMutationSetters: true })
class DispatchModule extends VuexModule {
  // state
  view: string = DispatchViewOptions['month']
  currentDate: dayjs.Dayjs = dayjs()
  selectFilters: any = {}
  searchQuery = ''
  selectedReservation: Reservation | null = null
  loadedReservations: Record<string, DispatchBlock> = {}
  loadedDispatchRequestDateIntervals = new IntervalTree()
  loading = false

  // getters
  get getMode(): string {
    return this.view
  }

  get isModeMonth(): boolean {
    return this.view === 'month'
  }

  get isModeDay(): boolean {
    return this.view === 'day'
  }

  get isModeWeek(): boolean {
    return this.view === 'week'
  }

  get isModeList(): boolean {
    return this.view === 'list'
  }

  get getCurrentDate(): dayjs.Dayjs {
    return this.currentDate
  }

  get getCurrentDateAsISO(): string {
    return this.currentDate.format('YYYY-MM-DD')
  }

  get getCurrentDateAsJSDate(): Date {
    return this.currentDate.toDate()
  }

  get getSelectFilters(): any {
    return this.selectFilters
  }

  get getSearchQuery(): string {
    return this.searchQuery
  }

  get getSelectedReservation(): Reservation | null {
    return this.selectedReservation
  }

  get getLoadedReservations(): Record<string, DispatchBlock> | null {
    return this.loadedReservations
  }

  get getLoadedDispatchRequestDateIntervals(): IntervalTree {
    return this.loadedDispatchRequestDateIntervals
  }

  get isLoading(): boolean {
    return this.loading
  }

  // actions
  @Action
  setMode(view: string): void {
    this.view = DispatchViewOptions[view]
  }

  @Action
  setCurrentDate(date: dayjs.Dayjs): void {
    this.currentDate = date
  }

  @Action
  incrementDate(): void {
    if (this.isModeDay || this.isModeList) {
      this.currentDate = this.currentDate.add(1, 'day')
    } else if (this.isModeWeek) {
      this.currentDate = this.currentDate.add(1, 'week')
    } else if (this.isModeMonth) {
      this.currentDate = this.currentDate.add(1, 'month')
    }
  }

  @Action
  decrementDate(): void {
    if (this.isModeDay || this.isModeList) {
      this.currentDate = this.currentDate.subtract(1, 'day')
    } else if (this.isModeWeek) {
      this.currentDate = this.currentDate.subtract(1, 'week')
    } else if (this.isModeMonth) {
      this.currentDate = this.currentDate.subtract(1, 'month')
    }
  }

  @Action
  setSearchQuery(query: string): void {
    this.searchQuery = query
  }

  @Action
  setSelectFilters(filters: any): void {
    this.selectFilters = filters
  }

  @Action
  clearSelectFilters(): void {
    this.selectFilters = []
  }

  @Action
  setSelectedReservation(reservation: Reservation): void {
    this.selectedReservation = reservation
  }

  @Action
  clearSelectedReservation(): void {
    this.selectedReservation = null
  }

  @Action
  setLoadedReservations(
    loadedReservations: Record<string, DispatchBlock>
  ): void {
    this.loadedReservations = loadedReservations
  }

  @Action
  updateLoadedDispatchRequestDateIntervals(dateInterval: DateInterval): void {
    // This process ensures that there are no overlapping intervals in the loadedDispatchRequestDateIntervals
    let interval = new Interval(
      dayjs(dateInterval.startDatetime).valueOf(),
      dayjs(dateInterval.endDatetime).valueOf()
    )
    const overlappingIntervals =
      this.loadedDispatchRequestDateIntervals.search(interval)
    for (const overlappingInterval of overlappingIntervals) {
      const overlap = new Interval(
        overlappingInterval[0],
        overlappingInterval[1]
      )
      this.loadedDispatchRequestDateIntervals.remove(overlap)
      interval = interval.merge(overlap)
    }
    this.loadedDispatchRequestDateIntervals.insert(interval)
  }

  @Action
  updateLoadedReservations(loadedReservations: DispatchBlock[]): void {
    const newReservations: Record<string, DispatchBlock> = {}
    for (const reservation of loadedReservations) {
      newReservations[reservation.reservationId] = reservation
    }
    this.loadedReservations = Object.assign(
      {},
      this.loadedReservations,
      newReservations
    )
  }

  @Action
  clearLoadedReservations(): void {
    this.loadedReservations = {}
    this.loadedDispatchRequestDateIntervals = new IntervalTree()
  }

  @Action
  setLoading(loading: boolean): void {
    this.loading = loading
  }
}

// register module (could be in any file)
export default new DispatchModule({ store, name: 'dispatch' })
