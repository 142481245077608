var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"padding-x-5 padding-t-1"},[_c('DispatchMiniCalendar'),_c('CUSelect',{staticClass:"rounded-lg margin-t-10",attrs:{"value":_vm.selectedStatusFilters,"flat":"","hide-details":"","outlined":"","display-select-all":"","display-select-all-inclusive":"","collapse-selection":"","persistent-placeholder":"","placeholder":"All","multiple":"","label":"Status","border-color":_vm.getSelectBorderColor(_vm.statusFilterOptions, _vm.selectedStatusFilters),"items":_vm.statusFilterOptions,"item-text":"label","item-value":"key"},on:{"input":(filters) => (_vm.selectedStatusFilters = filters)}}),_c('CUSelect',{staticClass:"rounded-lg margin-t-5",attrs:{"value":_vm.selectedAssignmentStatusFilters,"flat":"","hide-details":"","outlined":"","display-select-all":"","display-select-all-inclusive":"","collapse-selection":"","persistent-placeholder":"","placeholder":"All","multiple":"","label":"Assignment Status","border-color":_vm.getSelectBorderColor(
        _vm.assignmentStatusFilterOptions,
        _vm.selectedAssignmentStatusFilters
      ),"items":_vm.assignmentStatusFilterOptions,"item-text":"label","item-value":"key"},on:{"input":(filters) => (_vm.selectedAssignmentStatusFilters = filters)}}),_c('CUSelect',{staticClass:"rounded-lg margin-t-5",attrs:{"value":_vm.selectedDriverFilters,"flat":"","hide-details":"","outlined":"","display-select-all":"","display-select-all-inclusive":"","collapse-selection":"","persistent-placeholder":"","placeholder":"All","multiple":"","label":"Driver","border-color":_vm.getSelectBorderColor(_vm.driverFilterOptions, _vm.selectedDriverFilters),"items":_vm.driverFilterOptions,"item-text":"fullName","item-value":"userId"},on:{"input":(filters) => (_vm.selectedDriverFilters = filters)}}),_c('CUSelect',{staticClass:"rounded-lg margin-t-5",attrs:{"value":_vm.selectedVehicleFilters,"flat":"","hide-details":"","outlined":"","display-select-all":"","display-select-all-inclusive":"","collapse-selection":"","persistent-placeholder":"","placeholder":"All","multiple":"","label":"Vehicle Name","items":_vm.vehicleFilterOptions,"border-color":_vm.getSelectBorderColor(_vm.vehicleFilterOptions, _vm.selectedVehicleFilters),"item-text":"vehicleName","item-value":"vehicleId"},on:{"input":(filters) => (_vm.selectedVehicleFilters = filters)}}),_c('CUSelect',{staticClass:"rounded-lg margin-t-5",attrs:{"value":_vm.selectedVehicleTypeFilters,"flat":"","hide-details":"","outlined":"","display-select-all":"","display-select-all-inclusive":"","collapse-selection":"","persistent-placeholder":"","placeholder":"All","multiple":"","label":"Vehicle Type","items":_vm.vehicleTypeFilterOptions,"border-color":_vm.getSelectBorderColor(
        _vm.vehicleTypeFilterOptions,
        _vm.selectedVehicleTypeFilters
      ),"item-text":"label","item-value":"id"},on:{"input":(filters) => (_vm.selectedVehicleTypeFilters = filters)}}),_c('CUSelect',{staticClass:"rounded-lg margin-t-5",attrs:{"value":_vm.selectedGarageFilterOptions,"flat":"","hide-details":"","outlined":"","display-select-all":"","display-select-all-inclusive":"","collapse-selection":"","persistent-placeholder":"","placeholder":"All","multiple":"","label":"Garage","items":_vm.garageFilterOptions,"border-color":_vm.getSelectBorderColor(_vm.garageFilterOptions, _vm.selectedGarageFilterOptions),"item-text":"garageName","item-value":"garageId"},on:{"input":(filters) => (_vm.selectedGarageFilterOptions = filters)}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }