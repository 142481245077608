
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DispatchBlock } from '@/models/dto/Dispatch'
import { getReservationTimeLabel } from '@/utils/dispatch'
import { EventBus } from '@/utils/eventBus'

@Component({})
export default class DispatchCalendarItem extends Vue {
  @Prop({ required: true }) reservation!: DispatchBlock
  @Prop({ required: false, type: Boolean }) fullWidth!: boolean
  @Prop({ required: false, type: Boolean }) cvItem!: boolean
  @Prop({ required: false, type: Boolean }) isMultiDay!: boolean
  @Prop({ required: false, type: Number }) maxAssignmentsToDisplay!: number

  get isFullyAssigned(): boolean {
    return this.reservation.isFullyAssigned
  }

  get hasFarmout(): boolean {
    return this.reservation.affiliateOfferCount > 0
  }

  get innerWrapperStyle(): string {
    let style = ''
    if (this.cvItem) {
      style = `${style}  width: calc(100% - 6px); height: fit-content;`
    } else {
      style = `${style} height: calc(100% - 8px); width: calc(100% - 4px);`
    }
    return style
  }

  get blockBackgroundColor(): string {
    if (this.reservation.isFullyAssigned) {
      return 'background-green-10-opaque'
    } else {
      return 'background-red-10-opaque'
    }
  }

  get blockPrimaryColor(): string {
    if (this.reservation.isFullyAssigned) {
      return 'background-green'
    } else {
      return 'background-rust'
    }
  }

  get timeLabel(): string {
    return getReservationTimeLabel(this.reservation)
  }

  get iconColor(): string {
    if (!this.isFullyAssigned) {
      return 'bg-gray-4'
    }
    return 'green'
  }

  get itemHeaderStyle(): string {
    if (this.cvItem) {
      return ''
    }

    let style = '-webkit-box-orient: vertical;'
    if (this.reservation.durationInHours <= 1) {
      style = `${style} -webkit-line-clamp: 1;`
    } else if (this.reservation.durationInHours <= 1.5) {
      style = `${style} -webkit-line-clamp: 3;`
    } else {
      style = `${style} -webkit-line-clamp: 4;`
    }

    return style
  }

  get maxAssignments(): number {
    if (this.maxAssignmentsToDisplay) {
      return this.maxAssignmentsToDisplay
    }

    if (this.cvItem) {
      return 50
    }

    if (this.reservation.durationInHours <= 2) {
      return 0
    }
    return this.reservation.durationInHours - 2
  }

  openResDetailPanel(): void {
    EventBus.$emit('dispatch:open-reservation-detail-panel', {
      reservationId: this.reservation.reservationId,
      managedId: this.reservation.managedId,
    })
  }

  getHeaderLabelForReservation(r: DispatchBlock): string {
    const timeLabel = getReservationTimeLabel(r)
    let headerLabel = `${r.managedId}, ${timeLabel}`
    if (r.customerName) {
      headerLabel = `${headerLabel}, ${r.customerName}`
    }
    if (r.customerCompany) {
      headerLabel = `${headerLabel}, ${r.customerCompany}`
    }
    if (r.customerAccountGroup) {
      headerLabel = `${headerLabel}, ${r.customerAccountGroup}`
    }

    return headerLabel
  }
}
