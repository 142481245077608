
import { Vue, Component, Watch } from 'vue-property-decorator'
import DispatchMiniCalendar from '@/components/DispatchMiniCalendar.vue'
import dispatch from '@/store/modules/dispatch'
import type from '@/services/type'
import driver from '@/services/driver'
import vehicle from '@/services/vehicle'
import garage from '@/services/garage'
import { reservationStatusTypes } from '@/data/types'

@Component({
  components: { DispatchMiniCalendar },
})
export default class DispatchLeftSidebar extends Vue {
  statusFilterOptions = reservationStatusTypes
  assignmentStatusFilterOptions = []
  driverFilterOptions = []
  vehicleFilterOptions = []
  vehicleTypeFilterOptions = []
  garageFilterOptions = []

  selectedStatusFilters = []
  selectedAssignmentStatusFilters = []
  selectedDriverFilters = []
  selectedVehicleFilters = []
  selectedVehicleTypeFilters = []
  selectedGarageFilterOptions = []

  @Watch('selectedStatusFilters')
  @Watch('selectedAssignmentStatusFilters')
  @Watch('selectedDriverFilters')
  @Watch('selectedVehicleFilters')
  @Watch('selectedVehicleTypeFilters')
  @Watch('selectedGarageFilterOptions')
  setFilters(): void {
    dispatch.setSelectFilters(this.filters)
  }

  getSelectBorderColor(items: unknown[], value: unknown[]): string {
    return !!value.length && value.length !== items.length ? 'green' : null
  }

  get filters(): any {
    const statusFilter =
      this.selectedStatusFilters.length === this.statusFilterOptions.length
        ? []
        : this.selectedStatusFilters
    const assignmentStatusFilter =
      this.selectedAssignmentStatusFilters.length ===
      this.assignmentStatusFilterOptions.length
        ? []
        : this.selectedAssignmentStatusFilters
    const driverFilter =
      this.selectedDriverFilters.length === this.driverFilterOptions.length
        ? []
        : this.selectedDriverFilters
    const vehicleFilter =
      this.selectedVehicleFilters.length === this.vehicleFilterOptions.length
        ? []
        : this.selectedVehicleFilters
    const vehicleTypeFilter =
      this.selectedVehicleTypeFilters.length ===
      this.vehicleTypeFilterOptions.length
        ? []
        : this.selectedVehicleTypeFilters
    const garageFilter =
      this.selectedGarageFilterOptions.length ===
      this.garageFilterOptions.length
        ? []
        : this.selectedGarageFilterOptions
    return {
      statusKeys: statusFilter,
      assignmentStatusKeys: assignmentStatusFilter,
      driverIds: driverFilter,
      vehicleIds: vehicleFilter,
      vehicleTypeIds: vehicleTypeFilter,
      garageIds: garageFilter,
    }
  }

  getAssignmentStatusFilterOptions(): void {
    this.assignmentStatusFilterOptions = [
      {
        label: 'Fully Assigned',
        key: 'fully assigned',
      },
      {
        label: 'Needs Assignment',
        key: 'needs assignment',
      },
    ]
  }

  async getDrivers(): Promise<void> {
    const driversListRes = await driver.tableView({ pageSize: -1, page: 1 })
    this.driverFilterOptions = driversListRes.data.resultList.map((d) => ({
      ...d,
      fullName: `${d.firstName} ${d.lastName}`,
    }))
  }

  async getVehicles(): Promise<void> {
    const vehiclesListRes = await vehicle.tableView({ pageSize: -1, page: 1 })
    this.vehicleFilterOptions = vehiclesListRes.data.resultList
  }

  async getVehicleTypes(): Promise<void> {
    const response = await type.vehicleTypeTableView({ pageSize: -1 })
    this.vehicleTypeFilterOptions = response.data.resultList
  }

  async getGarages(): Promise<void> {
    const garageResponse = await garage.tableView({ pageSize: -1 })
    this.garageFilterOptions = garageResponse.data.resultList
  }

  created(): void {
    dispatch.clearSelectFilters()
    Promise.all([
      this.getAssignmentStatusFilterOptions(),
      this.getDrivers(),
      this.getVehicles(),
      this.getVehicleTypes(),
      this.getGarages(),
    ])
  }
}
