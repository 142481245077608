var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex align-center position-relative margin-y-1 border-radius-5 hover:cursor-pointer",class:{
    [_vm.blockBackgroundColor]: true,
  },on:{"click":_vm.openResDetailPanel}},[_c('div',{staticClass:"position-absolute",class:_vm.blockPrimaryColor,style:({
      width: '2px',
      borderRadius: '50px',
      left: '2px',
      top: '2px',
      bottom: '3px',
      height: 'calc(100% - 5px)',
    })}),_c('p',{staticClass:"margin-l-2 small-1 small-1-regular"},[_vm._v(" "+_vm._s(_vm.reservation.managedId)+" "+_vm._s(_vm.reservation.customerName)+", "+_vm._s(_vm.timeLabel)+", ")]),_vm._l((_vm.reservation.model),function(assignment,assignmentIndex){return _c('span',{key:`assignment-${_vm.reservation.reservationId}-${assignmentIndex}`,staticClass:"d-flex"},[_c('CUIcon',{style:({ margin: '0 2px', marginTop: '1px' }),attrs:{"aria-label":"Vehicle","height":"14px","width":"14px","color":_vm.iconColor}},[_vm._v(" bus_plain ")]),_c('p',{staticClass:"font-12 leading-16 min-h-16"},[_vm._v(" "+_vm._s(_vm.getAssignmentString(assignment))+" "),(assignmentIndex !== _vm.reservation.model.length - 1)?[_vm._v(" , ")]:_vm._e()],2)],1)}),(_vm.hasFarmout)?_c('div',{staticClass:"h-12 w-12 d-flex align-center justify-center rounded-pill background-additional-blue-2 border-1 border-solid border-additional-blue margin-l-1"},[_c('p',{staticClass:"text-additional-blue font-bold font-9"},[_vm._v("F")])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }