
import { Vue, Component } from 'vue-property-decorator'
import dispatch from '@/store/modules/dispatch'
import { CalendarView } from 'vue-simple-calendar'
import dayjs from 'dayjs'

@Component({
  components: { CalendarView },
})
export default class DispatchMiniCalendar extends Vue {
  dispatch = dispatch
  dayjs = dayjs

  displayDate = dispatch.getCurrentDate

  decrementDate(): void {
    this.displayDate = dayjs(this.displayDate).add(-1, 'month')
  }

  incrementDate(): void {
    this.displayDate = dayjs(this.displayDate).add(1, 'month')
  }

  setDate(day: string): void {
    dispatch.setCurrentDate(dayjs(day))
  }

  get date(): string {
    return this.displayDate.format('MMMM YYYY')
  }
}
