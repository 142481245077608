
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import {
  DispatchBlock,
  DispatchReservationType,
  ListDispatchBlock,
} from '@/models/dto/Dispatch'
import dayjs from 'dayjs'
import dispatch from '@/store/modules/dispatch'
import { formatFullName } from '@/utils/string'
import DispatchListCalendarItem from './DispatchListCalendarItem.vue'
import { doesBlockOverlapDates } from '@/utils/dispatch'
import auth from '@/store/modules/auth'

interface DispatchListviewDay {
  date: string
  reservations: ListDispatchBlock[]
}

@Component({ components: { DispatchListCalendarItem } })
export default class DispatchListCalender extends Vue {
  @Prop({ default: [] }) readonly reservations!: DispatchBlock[]

  dayjs = dayjs
  formatFullName = formatFullName
  dispatch = dispatch
  distanceOfTimeMarkerFromTop = null

  days: DispatchListviewDay[] = []

  @Watch('dispatch.getCurrentDate', { deep: true, immediate: true })
  @Watch('reservations')
  onDateChange(): void {
    this.organizeReservations()
    this.$nextTick(() => this.setCurrentTimeMarkerTop())
  }

  setCurrentTimeMarkerTop(): void {
    this.distanceOfTimeMarkerFromTop = null
    const dayIndex = this.days.findIndex((day) => {
      return dayjs(day.date).isSame(dayjs(), 'day')
    })
    if (dayIndex === -1) {
      return
    }
    let reservationId = null
    for (const reservation of this.days[dayIndex].reservations) {
      if (
        (reservation.type === 'single-day' ||
          reservation.type === 'multi-day-ending-today') &&
        dayjs().isSameOrAfter(dayjs(reservation.endDate))
      ) {
        reservationId = reservation.reservationId
      }
    }
    let el
    if (reservationId !== null) {
      if (
        this.days[dayIndex].reservations[
          this.days[dayIndex].reservations.length - 1
        ].reservationId === reservationId
      ) {
        el = this.$refs[
          `dispatch-list-view-day-${this.days[dayIndex].date}`
        ]?.[0] as HTMLElement
        if (!el) {
          return
        }
        this.distanceOfTimeMarkerFromTop = el.offsetTop + el.clientHeight
        return
      }
      el = this.$refs[
        `dispatch-list-calendar-item-${reservationId}-${this.days[dayIndex].date}`
      ]?.[0].$el as HTMLElement
      if (!el) {
        return
      }
      this.distanceOfTimeMarkerFromTop =
        el.offsetTop +
        el.clientHeight +
        parseInt(getComputedStyle(el).marginBottom) -
        3
      return
    }
    el = this.$refs[
      `dispatch-list-view-day-${this.days[dayIndex].date}`
    ]?.[0] as HTMLElement
    if (!el) {
      return
    }
    this.distanceOfTimeMarkerFromTop =
      el.offsetTop - parseInt(getComputedStyle(el).marginTop) - 1.25
  }

  initializeDays(): DispatchListviewDay[] {
    const days: DispatchListviewDay[] = []
    for (let day = 0; day < 7; day++) {
      const singleDay: DispatchListviewDay = {
        date: dispatch.getCurrentDate.add(day, 'day').format('YYYY-MM-DD'),
        reservations: [],
      }
      days.push(singleDay)
    }
    return days
  }

  organizeReservations(): void {
    const days = this.initializeDays()
    const currentWeekItems = this.reservations
      .filter((r) => {
        const startOfWeek = dayjs(days[0].date)
          .tz(auth.getUserTimeZone)
          .startOf('day')
        const endOfWeek = dayjs(days[6].date)
          .tz(auth.getUserTimeZone)
          .endOf('day')
        return doesBlockOverlapDates(r, startOfWeek, endOfWeek)
      })
      .sort(
        (a, b) => dayjs(a.startDate).valueOf() - dayjs(b.startDate).valueOf()
      )
    for (const day of days) {
      const startOfDay = dayjs(day.date).tz(auth.getUserTimeZone).startOf('day')
      const endOfDay = dayjs(day.date).tz(auth.getUserTimeZone).endOf('day')
      const dayItems = currentWeekItems.filter((r) =>
        doesBlockOverlapDates(r, startOfDay, endOfDay, false)
      )
      const typedDayItems: ListDispatchBlock[] = []
      for (const reservation of dayItems) {
        let type: DispatchReservationType
        const startsBefore = dayjs(reservation.startDatetime)
          .tz(auth.getUserTimeZone)
          .isBefore(startOfDay)
        const endsAfter = dayjs(reservation.endDatetime)
          .tz(auth.getUserTimeZone)
          .isAfter(endOfDay)
        if (!startsBefore && !endsAfter) {
          type = 'single-day'
        } else if (startsBefore && !endsAfter) {
          type = 'multi-day-ending-today'
        } else if (startsBefore && endsAfter) {
          type = 'multi-day-spanning-today'
        } else if (!startsBefore && endsAfter) {
          type = 'multi-day-starting-today'
        }
        typedDayItems.push({ ...reservation, type })
      }
      day.reservations = typedDayItems
    }
    this.days = days
  }
}
