var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"position-relative",style:(_vm.cssVars)},[_c('v-calendar',{ref:"calendar",staticClass:"dispatch-day-calendar border-radius-30 box-shadow-medium",class:{
      'background-gray-bg': _vm.state.getCurrentDate.isBefore(_vm.dayjs(), 'day'),
    },attrs:{"value":_vm.state.getCurrentDate.format('YYYY-MM-DD'),"type":"day","interval-height":"50"},scopedSlots:_vm._u([{key:"day-header",fn:function({ day, weekday }){return [_c('div',{staticClass:"padding-l-5 background-secondary-light border-radius-none d-flex align-center",style:({ height: '35px' })},[_c('div',{staticClass:"background-green border-radius-round text-white font-14 d-flex align-center justify-center font-bold",style:({ height: '22px', width: '22px' })},[_vm._v(" "+_vm._s(day)+" ")]),_c('span',{staticClass:"text-green font-bold font-14 margin-l-1"},[_vm._v(" "+_vm._s(_vm.indexToWeekday(weekday))+" ")])]),_c('div',{ref:"multiday-wrapper",staticClass:"margin-l-15 margin-r-3 background-white",class:{
          'border-b-1 border-solid border-t-0 border-x-0 border-bg-gray-2':
            !!_vm.multiDayItems.length,
        }},[_vm._l((_vm.multiDayItems),function(item,itemIndex){return [_c('DispatchMultidayCalendarItem',{key:`multi-day-${itemIndex}`,attrs:{"reservation":item}})]})],2)]}},{key:"interval",fn:function({ hour }){return [(hour === 0)?_c('div',{staticClass:"dispatch-day-calendar--event-grid display-grid h-full position-absolute w-full",style:({
          gridTemplateRows: 'repeat(24, 12px 13px 13px 12px)',
          gridTemplateColumns: 'repeat(auto-fill, 150px)',
        })},[_vm._l((_vm.plottedDayItems),function(item,itemIndex){return [_c('DispatchCalendarItem',{key:`item-${itemIndex}`,style:({
              gridColumnStart: item.coordinates.x0,
              gridColumnEnd: item.coordinates.x1,
              gridRowStart: item.coordinates.y0,
              gridRowEnd: item.coordinates.y1,
            }),attrs:{"reservation":item,"full-width":""}})]})],2):_vm._e()]}}])}),(_vm.currentTimeDistanceFromTop)?_c('div',{staticClass:"position-absolute w-full h-4 background-primary",style:({
      height: '2px',
      width: 'calc(100% - 71px)',
      left: '59px',
      right: '12px',
      top: _vm.currentTimeDistanceFromTop,
    })}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }