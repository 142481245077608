
import { Vue, Component } from 'vue-property-decorator'
import dispatch from '@/store/modules/dispatch'
import { DispatchViewOptions } from '@/utils/dispatch'
import { toTitle } from '@/utils/string'
import dayjs from 'dayjs'

@Component({})
export default class DispatchHeader extends Vue {
  dispatch = dispatch
  dayjs = dayjs

  get headerString(): string {
    return dispatch.getCurrentDate.format('MMMM YYYY')
  }

  get menuItems(): any {
    return Object.values(DispatchViewOptions).map((view) => {
      return {
        label: toTitle(view),
        key: view,
      }
    })
  }
}
