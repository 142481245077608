
import {
  Coordinates,
  DispatchBlock,
  DispatchMonthMap,
  DispatchMonthWeeksMap,
} from '@/models/dto/Dispatch'
import dispatch from '@/store/modules/dispatch'
import { DAYS_IN_WEEK } from '@/utils/time'
import dayjs, { Dayjs } from 'dayjs'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { CalendarView } from 'vue-simple-calendar'
import DispatchMonthCalendarItem from './DispatchMonthCalenderItem.vue'
import { doBoxesOverlap, doesBlockOverlapDates } from '@/utils/dispatch'
import deepClone from '@/utils/deepClone'

const ITEM_TOP = 40
const ITEM_CONTENT_HEIGHT = 22
const ITEM_BORDER_HEIGHT = 2
const ITEM_FULL_HEIGHT = ITEM_CONTENT_HEIGHT + ITEM_BORDER_HEIGHT
const MINIMUM_WEEK_HEIGHT = 160
const MAX_ITEMS_FOR_COLLAPSED_WEEK = 4
const MORE_BUTTON_BOTTOM_BUFFER = 24
const CALENDAR_DAY_HEADER_HEIGHT = 40
@Component({
  components: { CalendarView, DispatchMonthCalendarItem },
})
export default class DispatchMonthCalendar extends Vue {
  @Prop({}) readonly reservations!: DispatchBlock[]

  dayjs = dayjs
  dispatch = dispatch

  ITEM_CONTENT_HEIGHT = ITEM_CONTENT_HEIGHT
  ITEM_TOP = ITEM_TOP
  ITEM_BORDER_HEIGHT = ITEM_BORDER_HEIGHT

  weekExpanded = {}

  get monthStart(): Dayjs {
    return this.dispatch.getCurrentDate.startOf('month')
  }

  get monthEnd(): Dayjs {
    return this.dispatch.getCurrentDate.endOf('month')
  }

  @Watch('dispatch.getCurrentDate', { immediate: true })
  @Watch('dispatch.getMode')
  resetWeekExpanded(): void {
    this.weekExpanded = {
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
    }
  }

  toggleShowMore(day: Date): void {
    this.weekExpanded[this.weekIndexByDay(day)] =
      !this.weekExpanded[this.weekIndexByDay(day)]
  }

  weekIndexByDay(day: Date | string): number {
    return this.weeksInCalendar.findIndex((week) => {
      return dayjs(day).isSame(dayjs(week), 'week')
    })
  }

  moreButtonCount(day: Date): string {
    if (this.weekExpanded[this.weekIndexByDay(day)]) {
      return 'Show less'
    }
    const date = dayjs(day).format('YYYY-MM-DD')

    const count = this.hiddenResCountByDay[date] || 0
    return `+ ${count} more`
  }

  showMoreButton(day: Date): boolean {
    if (!dayjs(day).isBetween(this.monthStart, this.monthEnd, 'day', '[]')) {
      return false
    }
    const date = dayjs(day).format('YYYY-MM-DD')
    const numHiddenReservations = this.hiddenResCountByDay[date] || 0

    return numHiddenReservations > 0
  }

  showReservation(value: any, weekStartDate: string): boolean {
    if (this.weekExpanded[this.weekIndexByDay(weekStartDate)]) {
      return true
    }

    const weekStart = this.toISODate(weekStartDate)
    const { reservationId } = value.originalItem
    const coords: Coordinates =
      this.weekAndResIdToCoordinates[weekStart][reservationId]
    if (coords?.y0 == null) {
      return false
    }
    return coords.y0 < MAX_ITEMS_FOR_COLLAPSED_WEEK
  }

  get weekHeightManager(): any {
    const weekHeightManager = {}
    for (const weekIndex in this.weekExpanded) {
      if (this.weekExpanded[weekIndex]) {
        const calculatedHeight =
          this.maximumItemsPerWeekIndex[weekIndex] * ITEM_FULL_HEIGHT +
          ITEM_TOP +
          MORE_BUTTON_BOTTOM_BUFFER
        weekHeightManager[weekIndex] = Math.max(
          calculatedHeight,
          MINIMUM_WEEK_HEIGHT
        )
      } else {
        weekHeightManager[weekIndex] = MINIMUM_WEEK_HEIGHT
      }
    }
    return weekHeightManager
  }

  get weeksInCalendar(): string[] {
    const weekList = []
    const firstDayOfMonth = dispatch.getCurrentDate.startOf('month')
    const firstDayOfMonthDayIndex = firstDayOfMonth.day()
    const firstDayOfCalendar = firstDayOfMonth.subtract(
      firstDayOfMonthDayIndex,
      'day'
    )
    for (const weekIndex in this.weekExpanded) {
      weekList.push(
        dayjs(firstDayOfCalendar)
          .add(Number(weekIndex) * DAYS_IN_WEEK, 'days')
          .format('YYYY-MM-DD')
      )
    }
    return weekList
  }

  get maximumItemsPerWeekIndex(): any {
    const weekReservationsCount = {}
    for (const [weekIndex, week] of this.weeksInCalendar.entries()) {
      let mostReservationsInADay = 0
      for (let dayIndex = 0; dayIndex < DAYS_IN_WEEK; dayIndex++) {
        const day = dayjs(week).add(dayIndex, 'day').format('YYYY-MM-DD')
        if (this.monthMap[day]?.length) {
          mostReservationsInADay = Math.max(
            mostReservationsInADay,
            this.monthMap[day].length
          )
        }
      }
      weekReservationsCount[weekIndex] = mostReservationsInADay
    }
    return weekReservationsCount
  }

  get monthMap(): any {
    const monthMap = {}
    for (const reservation of this.reservations) {
      const startDate = dayjs(reservation.startDate).format('YYYY-MM-DD')
      const endDate = dayjs(reservation.endDate).format('YYYY-MM-DD')
      const duration = dayjs(endDate).diff(startDate, 'day')
      for (let dayCount = 0; dayCount <= duration; dayCount++) {
        const day = dayjs(reservation.startDate)
          .add(dayCount, 'day')
          .format('YYYY-MM-DD')
        if (monthMap[day]) {
          monthMap[day].push(reservation)
        } else {
          monthMap[day] = [reservation]
        }
      }
    }
    return monthMap
  }

  get cssVars(): Record<string, string> {
    return {
      '--week1Height': `${this.weekHeightManager[0]}px`,
      '--week2Height': `${this.weekHeightManager[1]}px`,
      '--week3Height': `${this.weekHeightManager[2]}px`,
      '--week4Height': `${this.weekHeightManager[3]}px`,
      '--week5Height': `${this.weekHeightManager[4]}px`,
      '--week6Height': `${this.weekHeightManager[5]}px`,
    }
  }

  get reservationsInMonth(): DispatchBlock[] {
    const monthStart = this.dispatch.getCurrentDate.startOf('month')
    const monthEnd = this.dispatch.getCurrentDate.endOf('month')

    const currentMonthReservations = deepClone(this.reservations).filter((r) =>
      doesBlockOverlapDates(r, monthStart, monthEnd)
    )
    return currentMonthReservations
  }

  // Return an object of reservations sorted by week
  get weeksMap(): Record<string, DispatchMonthWeeksMap> {
    const weeks = this.weeksInCalendar
    const map = {}
    for (const week of weeks) {
      const startOfWeek = dayjs(week).startOf('week')
      const endOfWeek = dayjs(week).endOf('week')

      const weekReservations = this.reservationsInMonth.filter((r) =>
        doesBlockOverlapDates(r, startOfWeek, endOfWeek)
      )

      map[week] = {
        reservations: weekReservations,
        start: startOfWeek,
        end: endOfWeek,
      }
    }

    return map
  }

  // Given a reservation and a start/end date, if the reservation goes outside
  // of these boundaries, reset the reservation's start/end dates to the bounds
  clipReservationWithBounds(
    r: DispatchBlock,
    start: Dayjs,
    end: Dayjs
  ): DispatchBlock {
    let reservationStartDate = dayjs(r.startDatetime)
    let reservationEndDate = dayjs(r.endDatetime)

    if (reservationStartDate.isBefore(start)) {
      r.startDatetime = dayjs(start).format()
      reservationStartDate = dayjs(r.startDatetime)
    }

    if (reservationEndDate.isAfter(end)) {
      r.endDatetime = dayjs(end).toISOString()
      reservationEndDate = dayjs(r.endDatetime)
    }

    r.isMultiDay = reservationStartDate.day() !== reservationEndDate.day()
    return r
  }

  // Returns an object mapping the ISO date of a week to all the
  // reservations within that week, all of which have coordinates
  // stored on them
  get plottedReservations(): Record<string, DispatchMonthWeeksMap> {
    const weeksMap = deepClone(this.weeksMap)

    for (const weekOfReservations of Object.values(weeksMap)) {
      let { reservations } = weekOfReservations
      const { start, end } = weekOfReservations
      const storedCoordinates = []
      const unavailableRowsForDay = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
      }

      // Loop through reservations--if they start before the start of the week
      // or end after the end of the week, set their start/end dates to
      // the beginning/end of the week (for the purpose of plotting coordinates)
      reservations = reservations.map((r) =>
        this.clipReservationWithBounds(r, start, end)
      )

      const multiDayReservations = reservations.filter((r) => r.isMultiDay)
      const singleDayReservations = reservations.filter((r) => !r.isMultiDay)

      // Plot multi-day reservation coordinates, and track the number of rows/multidays
      // in each day of the week
      for (const res of multiDayReservations) {
        let initialCoordinates = this.getCoordinatesForReservation(res, 0)
        for (let i = 0; i < storedCoordinates.length; i++) {
          if (doBoxesOverlap(storedCoordinates[i], initialCoordinates)) {
            initialCoordinates = this.getCoordinatesForReservation(
              res,
              initialCoordinates.y0 + 1
            )
            i = -1
          }
        }
        res.coordinates = initialCoordinates
        storedCoordinates.push(initialCoordinates)

        for (let j = initialCoordinates.x0; j < initialCoordinates.x1; j++) {
          unavailableRowsForDay[j].push(initialCoordinates.y0)
        }
      }

      // Plot single day reservation coordinates by getting a starting height
      // from the number of multiday items in that day
      for (const res of singleDayReservations) {
        const day = dayjs(res.startDatetime).day()
        let row = 0
        while (unavailableRowsForDay[day].includes(row)) {
          row += 1
        }
        const coords = {
          x0: day,
          x1: day + 1,
          y0: row,
          y1: row + 1,
        }
        res.coordinates = coords
        unavailableRowsForDay[day].push(row)
        unavailableRowsForDay[day].sort()
      }
    }

    return weeksMap
  }

  get weekAndResIdToCoordinates(): DispatchMonthMap {
    const map = {}
    for (const [weekStart, weekInfo] of Object.entries(
      this.plottedReservations
    )) {
      const reservations = weekInfo.reservations
      const idToCoordinates = {}
      for (const reservation of reservations) {
        idToCoordinates[reservation.reservationId] = reservation.coordinates
      }
      map[weekStart] = idToCoordinates
    }

    return map
  }
  /**
   * Computes the number of hidden reservations for each day in the calendar.
   * A reservation is considered hidden if its y0 value is greater than or equal to MAX_ITEMS_FOR_COLLAPSED_WEEK.
   * @returns {Record<string, number>} An object mapping each date to the number of hidden reservations on that date.
   */
  get hiddenResCountByDay(): Record<string, number> {
    const result: Record<string, number> = {}

    if (!this.weekAndResIdToCoordinates) {
      return result
    }

    // iterate over each week
    for (const week in this.weekAndResIdToCoordinates) {
      // iterate over each reservation within that week
      for (const reservationId in this.weekAndResIdToCoordinates[week]) {
        const reservation = this.weekAndResIdToCoordinates[week][reservationId]
        const { x0, x1, y0 } = reservation

        // only consider the reservations with y0 >= the hidden reservation line
        if (y0 >= MAX_ITEMS_FOR_COLLAPSED_WEEK) {
          // calculate the actual date for each day the reservation covers
          for (let day = x0; day < x1; day++) {
            const currentDate = dayjs(week).add(day, 'day')
            const formattedDate = currentDate.format('YYYY-MM-DD')

            // increment the count for that date or initialize it
            if (result[formattedDate]) {
              result[formattedDate]++
            } else {
              result[formattedDate] = 1
            }
          }
        }
      }
    }

    return result
  }

  toISODate(date: string): string {
    return dayjs(date).format('YYYY-MM-DD')
  }

  getTopForReservation(weekStart: string, r: DispatchBlock): string {
    const week = this.toISODate(weekStart)
    const { reservationId: id } = r
    const coords: Coordinates = this.weekAndResIdToCoordinates[week][id]
    const y0 = coords
      ? coords.y0 * ITEM_FULL_HEIGHT + CALENDAR_DAY_HEADER_HEIGHT
      : 0
    return `${y0}px`
  }

  getCoordinatesForReservation(r: DispatchBlock, offsetY = 0): Coordinates {
    return {
      x0: dayjs(r.startDatetime).weekday(),
      x1: dayjs(r.endDatetime).weekday() + 1,
      y0: offsetY,
      y1: offsetY + 1,
    }
  }
}
