
import {
  DispatchBlock,
  DispatchBlockVehicleAssignment,
} from '@/models/dto/Dispatch'
import { EventBus } from '@/utils/eventBus'
import { Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'

@Component({})
export default class DispatchMultidayCalendarItem extends mixins(DateMixin) {
  @Prop({ required: true }) reservation!: DispatchBlock

  get blockBackgroundColor(): string {
    if (this.reservation.isFullyAssigned) {
      return 'background-green-10-opaque'
    } else {
      return 'background-red-10-opaque'
    }
  }

  get blockPrimaryColor(): string {
    if (this.reservation.isFullyAssigned) {
      return 'background-green'
    } else {
      return 'background-rust'
    }
  }

  get timeLabel(): string {
    const startDatetime = this.formatShortMonthDay(
      this.reservation.startDatetime
    )
    const endDatetime = this.formatShortMonthDay(this.reservation.endDatetime)

    return `${startDatetime}-${endDatetime}`
  }

  get iconColor(): string {
    if (!this.reservation.isFullyAssigned) {
      return 'bg-gray-4'
    }
    return 'green'
  }

  get hasFarmout(): boolean {
    return this.reservation.affiliateOfferCount > 0
  }

  openResDetailPanel(): void {
    EventBus.$emit('dispatch:open-reservation-detail-panel', {
      reservationId: this.reservation.reservationId,
      managedId: this.reservation.managedId,
    })
  }

  getAssignmentString(assignment: DispatchBlockVehicleAssignment): string {
    if (!assignment.vehicleName) {
      return ' - '
    }
    let assignmentString = assignment.vehicleName
    const driverName = assignment.driverAssignments[0]?.driverName
    if (driverName) {
      assignmentString = `${driverName}-${assignmentString}`
    }
    return assignmentString
  }
}
