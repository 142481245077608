
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import DispatchCalendarItem from '@/components/DispatchCalendarItem.vue'
import { CalendarView } from 'vue-simple-calendar'
import dispatch from '@/store/modules/dispatch'
import dayjs, { Dayjs } from 'dayjs'
import {
  doBoxesOverlap,
  doesBlockOverlapDates,
  getReservationTimeLabel,
} from '@/utils/dispatch'
import deepClone from '@/utils/deepClone'
import { DispatchBlock } from '@/models/dto/Dispatch'
import { getWidthOfTextInFont } from '@/utils/string'
@Component({
  components: { DispatchCalendarItem, CalendarView },
})
export default class DispatchWeekCalendar extends Vue {
  @Prop({ required: true }) reservations!: Record<string, DispatchBlock>

  state = dispatch
  MINIMUM_DISPATCH_HEIGHT = 86
  MINIMUM_CALENDAR_HEIGHT = 600
  INDIVIDUAL_ASSIGNMENT_HEIGHT = 36
  HEADER_LINE_HEIGHT = 16
  calendarDayWidth = 120
  canvas = null

  @Watch('mode')
  onModeChange(): void {
    this.setCalendarDayWidth()
  }

  // COMPUTEDS
  get currentDate(): Date {
    return dispatch.getCurrentDateAsJSDate
  }

  get weekStart(): Dayjs {
    return dispatch.getCurrentDate.startOf('week')
  }

  get weekEnd(): Dayjs {
    return dispatch.getCurrentDate.endOf('week')
  }

  get mode(): string {
    return dispatch.getMode
  }

  get calendarHeight(): number {
    const lowestHeight = this.plottedWeekItems.reduce((lowestHeight, block) => {
      return Math.max(lowestHeight, block.coordinates.y1)
    }, 0)
    return Math.max(lowestHeight, this.MINIMUM_CALENDAR_HEIGHT)
  }

  get cssVars(): Record<string, string | number> {
    return {
      '--minimum-calendar-height': `${this.calendarHeight}px`,
    }
  }

  // Returns the list of filtered reservations that fall between weekStart
  // and weekEnd
  get currentWeekItems(): DispatchBlock[] {
    let reservations: DispatchBlock[] = Object.values(this.reservations) || []

    reservations = reservations
      .filter((r) => doesBlockOverlapDates(r, this.weekStart, this.weekEnd))
      .map((r) => {
        const res = deepClone(r)
        if (dayjs(res.startDate).isBefore(this.weekStart)) {
          res.startDate = this.weekStart.toISOString()
        }

        if (dayjs(res.endDate).isAfter(this.weekEnd)) {
          res.endDate = this.weekEnd.toISOString()
        }

        if (!dayjs(res.startDate).isSame(dayjs(res.endDate), 'day')) {
          res.isMultiDay = true
        }

        return res
      })

    reservations
      .sort((a, b) => (a.startDatetime > b.startDatetime ? 1 : -1))
      .sort((a, b) => (a.isMultiDay ? -1 : 1))
    return reservations
  }

  // Maps through the items in the week, and plots coordinates for all of them
  // Store all the coordinates for each item in a temporary array
  // When plotting a new block, check for any overlap--if one exists,
  // move the block down, and try plotting it again
  get plottedWeekItems(): DispatchBlock[] {
    const storedCoordinates = []
    for (const weekItem of this.currentWeekItems) {
      let initialCoordinates = this.getCoordinatesForReservation(weekItem, 0)

      for (let i = 0; i < storedCoordinates.length; i++) {
        if (doBoxesOverlap(storedCoordinates[i], initialCoordinates)) {
          initialCoordinates = this.getCoordinatesForReservation(
            weekItem,
            storedCoordinates[i].y1
          )
          i = -1
        }
      }
      weekItem.coordinates = initialCoordinates
      storedCoordinates.push(initialCoordinates)
    }
    return this.currentWeekItems
  }

  // METHODS
  getCoordinatesForReservation(res: DispatchBlock, startingHeight = 0): any {
    // Calculate the height of a dispatch block by figuring out
    // how many lines its header will be, and how many assignments it has
    const headerLabel = this.getHeaderLabelForReservation(res)
    const numberOfLines = this.getNumberOfLinesInHeader(headerLabel)
    const numberOfAssignments = Math.max(res.model.length, 1)
    const PADDING_AND_MARGIN = 18

    let reservationHeight
    if (res.isMultiDay) {
      reservationHeight = 68
    } else {
      reservationHeight =
        numberOfLines * this.HEADER_LINE_HEIGHT +
        numberOfAssignments * this.INDIVIDUAL_ASSIGNMENT_HEIGHT +
        PADDING_AND_MARGIN
    }

    const x0 = dayjs(res.startDate).weekday()
    const x1 = dayjs(res.endDate).weekday() + 1
    const y0 = startingHeight
    const y1 = startingHeight + reservationHeight

    const coordinates = { x0, x1, y0, y1 }

    return coordinates
  }

  // Returns the string that will be the header for a reservation
  getHeaderLabelForReservation(r: DispatchBlock): string {
    const timeLabel = getReservationTimeLabel(r)
    let headerLabel = `${r.managedId}, ${timeLabel}`
    if (r.customerName) {
      headerLabel = `${headerLabel}, ${r.customerName}`
    }
    if (r.customerCompany) {
      headerLabel = `${headerLabel}, ${r.customerCompany}`
    }
    if (r.customerAccountGroup) {
      headerLabel = `${headerLabel}, ${r.customerAccountGroup}`
    }

    return headerLabel
  }

  // Iterate through a string, and based on the width of text in font method,
  // figure out where the line should break. Return the number of lines
  // in the header
  getNumberOfLinesInHeader(text: string): number {
    const maxWidth = this.calendarDayWidth - 14
    const words = text.split(' ')
    const lines = []
    let currentLine = words[0]

    for (let i = 1; i < words.length; i++) {
      const word = words[i]

      const width = getWidthOfTextInFont(
        `${currentLine} ${word}`,
        '12px',
        '700',
        this.canvas
      )
      if (width < maxWidth) {
        currentLine += ` ${word}`
      } else {
        lines.push(currentLine)
        currentLine = word
      }
    }
    lines.push(currentLine)
    return lines.length
  }

  // Workaround for vue-simple-calendar header slot
  // Input in the form of dow0, dow1, ..., dow6
  getHeaderDate(headerIndex: string): number {
    const index = Number(headerIndex.slice(3))
    return this.weekStart.add(index, 'day').date()
  }

  // Workaround for vue-simple-calendar header slot
  // Input in the form of dow0, dow1, ..., dow6
  isHeaderDateToday(headerIndex: string): boolean {
    const index = Number(headerIndex.slice(3))
    const day = this.weekStart.add(index, 'day')
    const today = dayjs()
    return today.isSame(day, 'day')
  }

  // Get the first day on the week calendar, and get its
  // inner width (used for calculating item height + position)
  setCalendarDayWidth(): void {
    const weekCalendar: any = this.$refs['week-calendar']
    if (!weekCalendar) {
      return
    }

    const day = weekCalendar.$el.querySelector('.cv-day')
    this.calendarDayWidth = day.clientWidth
  }

  // Guesstimate for now--for multi-day reservations, set the max
  // number of assignments displayed horizontally to be the
  // number of days the reservation spans
  getMaxAssignmentsForReservation(res: DispatchBlock): number {
    if (!res.isMultiDay) {
      return 50
    }

    const dayStart = dayjs(res.startDate)
    const dayEnd = dayjs(res.endDate)
    const duration = Math.abs(dayStart.diff(dayEnd))
    return duration
  }

  mounted(): void {
    this.setCalendarDayWidth()
    window.addEventListener('resize', this.setCalendarDayWidth)
  }
}
